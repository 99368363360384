<template>
  <div class="container">
    <div class="nav">
      <p class="navTitle">设备列表</p>
      <!-- <el-tree :data="navList" style="margin-left: 1vh;" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
      <p
        class="navList"
        v-for="(item, index) in navList"
        :class="index == deviceIndex ? 'active' : ''"
        :key="index"
        @click="deviceClick(index, item.deviceNum, item.id)"
      >
        {{ item.deviceName }}
      </p>
    </div>
    <div class="main">
      <div class="mainHead">
        <div class="mainHeadLeft">
          起止时间
          <el-date-picker
            v-model="value1"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            :default-time="['12:00:00']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="success"
            class="el-icon-search"
            @click="searchResult"
          ></el-button>
          <!-- <el-button type="success" @click="exportList">导出</el-button> -->
        </div>
        <div class="mainHeadRight">
          <!-- <el-button type="success" icon="el-icon-refresh" size="mini"></el-button> -->
        </div>
      </div>
      <div class="echart" id="echart">
        <el-table :data="tableDatas" empty-text="暂无数据" style="width: 100%">
          <el-table-column prop="deviceNumber" label="设备号" width="180">
          </el-table-column>
          <el-table-column prop="drecordTime" label="采集时间" width="180">
          </el-table-column>
          <el-table-column prop="sensorData" label="设备数据">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      navList: [],
      deviceIndex: 0,
      defaultProps: {
        children: "children",
        label: "label",
      },
      page: 1,
      limit: 10,
      total: 0,
      deviceNumber: "",
      deviceId: 0,
      tableDatas: [],
    };
  },
  mounted() {
    this.getTime();
    this.getNavList();
    // this.getData()
  },
  methods: {
    // 左侧菜单
    getNavList() {
      let arr = this.navList;
      this.$get("/sfsbgl/page", {
        page: 1,
        limit: 1000,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.data;
          this.deviceNumber = m[0].deviceNum;
          this.navList = m;
          this.getRightData();
          // for (let i = 0; i < m.length; i++) {
          //   arr.push({id: m[i].id, label: m[i].groupName, children: []})
          //   this.$get('/group/selDeviceForGroup', {
          //     id: m[i].id
          //   }).then((res) => {
          //     if (res.data.state == 'success') {
          //       let n = res.data.datas
          //       if (n && n.length > 0) {
          //         this.deviceNumber = n[0].deviceNumber
          //         this.getRightData()
          //         for (let j = 0; j < n.length; j++) {
          //           arr[i].children.push({id: n[j].id, label: n[j].deviceName, num: n[j].deviceNumber})
          //         }
          //       }
          //     }
          //   })
          // }
        }
      });
    },
    deviceClick(index, deviceNumber, deviceId) {
      this.deviceIndex = index;
      this.deviceNumber = deviceNumber;
      this.deviceId = deviceId;
      this.getRightData();
    },
    // 通过设备号获取数据
    getRightData() {
      //console.log(this.value1)
      // let time = [];
      // if (this.value1.length > 0) {
      //   time = this.value1;
      // }
      // let time2 = time[0].split('-');
      // let newTime1 = time2[0] +'-'+time2[1]+'-'+time2[2];
      // let time3 = time[1].split('-');
      // let newTime2 = time3[0]+'-'+time3[1]+'-'+time3[2];
      // let time1 = " 00:00:00";
      this.$get("/jxct/selHistoryForDate", {
        page: 1,
        size: 500,
        deviceNumber: this.deviceNumber,
        start: this.value1[0] || null,
        end: this.value1[1] || null
        // start: newTime1 +' ' + "00:00:00",
        // end: newTime2 + ' ' + '23:59:59',
        // id: this.deviceId,
        // ym: time[0] + time1 + '~' + time[1] + time1
      }).then((res) => {
        if (res.data.state == "success") {
          if (res.data.datas) {
            let m = res.data.datas;
            if (m.length > 0) {
              this.tableDatas = res.data.datas;
            } else {
              this.tableDatas = [];
            }
          } else {
            this.$message("暂无数据");
            this.tableDatas = [];
          }
        }
      });
    },
    // echarts
    getData(xData, arr) {
      let myChart = this.$echarts.init(document.getElementById("echart"));
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        tooltip: {
          trigger: "item",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: arr,
            // data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            areaStyle: {
              normal: {
                color: "#85ce61",
              },
            },
            itemStyle: {
              normal: {
                color: "#85ce61", // 改变区域颜色
                lineStyle: {
                  color: "#85ce61",
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 菜单点击事件
    handleNodeClick(e) {
      if (e.num) {
        this.deviceNumber = e.num;
        this.getRightData();
      }
    },
    // 点击搜索事件
    searchResult() {
      this.getRightData();
    },
    exportList() {
      let time = [];
      if (this.value1.length > 0) {
        time = this.value1;
      }
      let time1 = " 00:00:00";
      this.$get("/deviceExport/HistoryExportInformation", {
        deviceNumber: this.deviceNumber,
        // from: time[0] + time1,
        // to: time[1] + time1
      }).then((res) => {
        window.open(
          res.request.responseURL +
            "&from=" +
            time[0] +
            time1 +
            "&to=" +
            time[1] +
            time1
        );
      });
    },
    // 获取一周前的时间
    getTime() {
      var today = new Date();
      var year = today.getFullYear();
      var dateArr = [];
      for (var i = 0; i < 7; i++) {
        var newDate = new Date(today.getTime() - i * 1000 * 60 * 60 * 24);
        var month =
          parseInt(newDate.getMonth()) + 1 > 9
            ? parseInt(newDate.getMonth()) + 1
            : "0" + (parseInt(newDate.getMonth()) + 1);
        var day =
          newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate();
        var fullDate = `${year}-${month}-${day}`;
        if (i == 6) {
          dateArr.push(fullDate +' ' + '00:00:00');
        }
        if (i == 0) {
          dateArr.push(fullDate +' '+ "00:00:00");
        }
      }
      this.value1 = dateArr.reverse();
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 92vh;
  overflow: hidden;
  padding-left: 1vh;
  box-sizing: border-box;
  display: flex;
}
.nav {
  display: inline-block;
  width: 12vw;
  height: 85vh;
  margin-top: 1vh;
  margin-left: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  overflow-y: auto;
}
.navList {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  cursor: pointer;
  margin-top: 1vh;
}
.navList.active {
  color: #009688;
}
.nav::-webkit-scrollbar {
  display: none;
}
.navTitle {
  margin-top: 2vh;
  font-size: 1.5vh;
  color: #409eff;
  padding-left: 1vh;
  box-sizing: border-box;
}
.el-tree {
  margin-top: 1vh;
}
.main {
  display: inline-block;
  width: 72vw;
  height: 85vh;
  margin-left: 1.5vh;
  margin-top: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  padding: 1vh;
  box-sizing: border-box;
}
.mainHead {
  width: 100%;
  height: 4vh;
}
.mainHeadLeft {
  display: inline-block;
  width: 60%;
  box-sizing: border-box;
}
.mainHeadRight {
  display: inline-block;
  width: 39%;
  text-align: right;
  vertical-align: middle;
}
.mainHeadRight i {
  font-size: 1.8vh;
}
.el-pagination {
  margin-top: 2vh;
}
#echart {
  width: 100%;
  height: 78vh;
  margin-top: 3vh;
}
#echart /deep/ .el-table__body-wrapper {
  height: 72vh;
  overflow-y: auto;
}
</style>
<style>
.container .el-table td,
.container .el-table th {
  text-align: center !important;
}
</style>
